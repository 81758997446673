import { FulfillmentType } from "./Models";

export type ParseWithdrawFromCirculationFileResponse = {
  totalProducts: number;
  totalUniqueProducts: number;
  fulfillmentType: FulfillmentType;
  hasPrices: boolean;
};

export type PrepareWithdrawFromCirculationResponse = {
  documents: PrepareWithdrawFromCirculationDocument[];
  totalProducts: number;
  totalUniqueProducts: number;
  totalOwnProducts: number;
  fulfillmentType: FulfillmentType;
};

export type PrepareWithdrawFromCirculationDocument = {
  encryptedContent: string;
  type: CirculationDocumentType;
  productGroup: string;
  count: number;
};

export type ActualizeKizDocument = {
  encryptedContent: string;
  signature: string;
  type: CirculationDocumentType;
  productGroup: string;
};

export enum CirculationDocumentType {
  Withdraw = "Withdraw",
  Return = "Return",
}
