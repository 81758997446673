import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styles from "./Tooltip.module.scss";

const Tooltip: React.FC<{
  id: string;
  message: string;
  clickable?: boolean;
}> = ({ id, message, clickable }) => {
  return (
    <>
      <span data-tooltip-id={id} className={styles.question}>
        ?
      </span>
      <ReactTooltip
        id={id}
        place="top"
        content={message}
        variant="info"
        className={styles.tooltip}
        clickable={clickable ?? false}
      />
    </>
  );
};

export default Tooltip;
