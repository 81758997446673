import { Button } from "@mui/material";
import AcceptanceCertificate from "../WbAcceptanceCertificate/WbAcceptanceCertificate";
import { useEffect, useState } from "react";
import styles from "./UpdSender.module.scss";
import { CustomIcon } from "../../customIcon";
import FlashCertificateSelector from "../../FlashCertificateSelector/FlashCertificateSelector";
import {
  getAccessTokenInfo,
  prepareUpd,
  sendUpd,
} from "src/services/UpdApiService";
import { toast } from "react-toastify";
import axios from "axios";
import {
  AccessTokenInfo,
  MarketplaceType,
  ServerErrors,
  ActionType,
} from "src/models/Models";
import { Certificate, createSignature } from "crypto-pro";
import { useParams, Link } from "react-router-dom";
import UpdUploader from "../UpdUploader/UpdUploader";
import AccessTokenStatistics from "../../AccessTokenStatistics/AccessTokenStatistics";
import {
  autoCloseOptions,
  createOrUpdateInfoToast,
} from "src/utils/toastExtensions";
import AdditionalDetails from "../AdditionalDetails/AdditionalDetails";
import LamodaFile from "../LamodaFile/LamodaFile";
import { AdditionalInfo, SignerInfo } from "src/models/UpdModels";
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog";
import { useConfirm } from "src/components/ConfirmDialog/useConfirm";
import { Loader } from "src/components/Loader/Loader";
import Tooltip from "src/components/Tooltip/Tooltip";

type RouteParams = {
  token: string;
};

export default function UpdSender() {
  const [trueApiToken, setTrueApiToken] = useState<string>();
  const [certificate, setCertificate] = useState<Certificate>();
  const [isParsing, setIsParsing] = useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>();
  const [isLegalEntity, setIsLegalEntity] = useState<boolean>(false);
  const [isAddressMissing, setIsAddressMissing] = useState<boolean>(false);
  const [needConfirm, setNeedConfirm] = useState<boolean>(false);
  const [hasCriticalErrors, setHasCriticalErrors] = useState<boolean>(false);
  const [sentCount, setSentCount] = useState<number>(0);
  const [marketplaceType, setMarketplaceType] = useState<MarketplaceType>(
    MarketplaceType.Wildberries
  );
  const [updActionType, setUpdActionType] = useState<ActionType>(
    ActionType.SignAndSend
  );
  const [tokenInfo, setTokenInfo] = useState<AccessTokenInfo>();
  const [sellerInn, setSellerInn] = useState<string>();
  const { token } = useParams<RouteParams>();
  const { ask } = useConfirm();

  useEffect(() => {
    (async () => {
      if (!token) {
        setIsTokenValid(false);
        return;
      }

      let info = await getAccessTokenInfo(token);
      setIsTokenValid(info.isValid);
      setTokenInfo(info);

      createOrUpdateInfoToast("info", <AccessTokenStatistics info={info} />, {
        position: "top-left",
        toastId: "info",
        closeButton: true,
        icon: false,
      });

      createOrUpdateInfoToast(
        "adv",
        <div>
          <b>НОВАЯ функция бота</b>:
          <br />
          ✅ Проверка статусов КИЗ;
          <br />
          ✅ Вывод КИЗ из оборота;
          <br />
          ✅ Возврат КИЗ в оборот;
          <br />
          <br />
          Так же в 3 клика🔥
          <br />
          Подробнее{" "}
          <Link target="_blank" to="https://t.me/eazycheezychannel/24">
            в телеграм канале
          </Link>
        </div>,
        {
          position: "top-left",
          toastId: "adv",
          closeButton: true,
          icon: false,
        }
      );
    })();
  }, [token, sentCount]);

  async function handleOnClick() {
    if (clickButtonDisabled()) return;

    if (needConfirm) {
      let result = await ask("Вы уверены?");
      if (!result) {
        return;
      }
    }
    try {
      setIsParsing(true);

      let signerInfo = await getSignerInfo();

      let prepareUpdResult = await prepareUpd(
        trueApiToken!,
        uploadedFile!,
        token!,
        marketplaceType,
        signerInfo,
        isLegalEntity,
        sellerInn,
        additionalInfo?.agreementNumber,
        additionalInfo?.agreementDate
      );

      let signature = await createSignature(
        certificate!.thumbprint,
        prepareUpdResult.encryptedContent
      );

      await sendUpd(
        trueApiToken!,
        prepareUpdResult.encryptedContent,
        prepareUpdResult.fileName,
        token!,
        signature,
        marketplaceType,
        updActionType
      );

      const shouldRedirect =
        !!tokenInfo?.allowedUseCount &&
        tokenInfo.allowedUseCount - tokenInfo.usedCount === 1;
      if (shouldRedirect) {
        window.location.href = "/upd/last-upd-sent";
        return;
      }

      switch (updActionType) {
        case ActionType.SignAndSend:
          toast.success(
            <div>
              УПД успешно подписан и отправлен в Честный Знак.
              <br />
              <Link target="_blank" to="/upd/success-send">
                Как проверить?
              </Link>{" "}
            </div>
          );
          break;
        case ActionType.Download:
          toast.success(
            "УПД успешно отправлен в ваш телеграм",
            autoCloseOptions
          );
          break;
      }

      clearFileInputs();
    } catch (error) {
      if (axios.isAxiosError<ServerErrors>(error)) {
        let errorData: ServerErrors =
          error.response?.data instanceof Blob
            ? JSON.parse(await error.response?.data.text())
            : error.response?.data;
        toast.error(errorData.errors.join("; "));
      } else {
        console.error(error);
      }
    } finally {
      setIsParsing(false);
    }
  }

  const clearFileInputs = () => {
    setSentCount(sentCount + 1);
    setUploadedFile(undefined);
  };
  const handleMarketplaceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedMarketplace = event.target.value as MarketplaceType;
    setMarketplaceType(selectedMarketplace);
    setUploadedFile(undefined);
    setIsLegalEntity(false);
    setIsAddressMissing(false);
    setNeedConfirm(false);
    setUpdActionType(
      selectedMarketplace === MarketplaceType.YandexMarket
        ? ActionType.Download
        : updActionType
    );
  };

  const handleUpdActionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedUpdActionType = event.target.value as ActionType;
    setUpdActionType(selectedUpdActionType);
  };

  async function getSignerInfo() {
    if (isLegalEntity) {
      return {
        firstName: additionalInfo!.firstName,
        lastName: additionalInfo!.lastName,
        middleName: additionalInfo!.middleName,
        jobTitle: additionalInfo!.jobTitle,
        address: additionalInfo!.address,
      } as SignerInfo;
    }

    let ownerInfo = await certificate!.getOwnerInfo();

    console.log(ownerInfo);
    let lastName = ownerInfo
      .filter((x) => x.title === "Фамилия")[0]
      .description.trim();
    let firstMiddleName = ownerInfo
      .filter((x) => x.title === "Имя Отчество")[0]
      .description.split(" ");
    let firstName = firstMiddleName[0].trim();
    let middleName =
      firstMiddleName.length > 1
        ? firstMiddleName.slice(1).join(" ").trim()
        : null;

    let signerInfo = {
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      address: additionalInfo?.address,
    } as SignerInfo;
    return signerInfo;
  }

  function clickButtonDisabled(): boolean {
    return (
      !trueApiToken ||
      !uploadedFile ||
      isParsing ||
      (shouldAskAdditionalDetails(
        isLegalEntity,
        isAddressMissing,
        marketplaceType
      ) &&
        !additionalInfo)
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Отправьте УПД в 3 шага</div>

      <div className={styles.actions}>
        {!isTokenValid && (
          <div className={styles.error}>
            Исчерпан лимит отправок или ссылка недействительна. Чтобы отправить
            УПД запросите новую ссылку в{" "}
            <Link target="_blank" to={`${process.env.REACT_APP_TG_BOT_URL}`}>
              боте
            </Link>
          </div>
        )}

        <div>
          <div className={styles.actionCardHeader}>
            <CustomIcon name="key" className={styles.icon} />
            <div className={styles.stepLabel}>Выберите сертификат</div>
          </div>

          <FlashCertificateSelector
            onChange={(token, selectedCertificate, inn) => {
              setTrueApiToken(token);
              setCertificate(selectedCertificate);
              setSellerInn(inn);
            }}
            disabled={!isTokenValid}
          />
        </div>

        <div>
          <div className={styles.actionCardHeader}>
            <CustomIcon name="marketplace" className={styles.icon} />
            <div className={styles.stepLabel}>Выберите маркетплейс</div>
          </div>

          <div className={styles.marketplaces}>
            <label>
              <input
                type="radio"
                name="marketplaceType"
                value={MarketplaceType.Wildberries}
                onChange={handleMarketplaceChange}
                checked={marketplaceType === MarketplaceType.Wildberries}
                disabled={!isTokenValid}
              />
              <span className={styles.wildberries}>Wildberries</span>
            </label>
            <label>
              <input
                type="radio"
                name="marketplaceType"
                value={MarketplaceType.Ozon}
                onChange={handleMarketplaceChange}
                checked={marketplaceType === MarketplaceType.Ozon}
                disabled={!isTokenValid}
              />
              <span className={styles.ozon}>Ozon</span>
            </label>
            <label>
              <input
                type="radio"
                name="marketplaceType"
                value={MarketplaceType.DetMir}
                onChange={handleMarketplaceChange}
                checked={marketplaceType === MarketplaceType.DetMir}
                disabled={!isTokenValid}
              />
              <span className={styles.detMir}>Детский Мир</span>
            </label>
            <label>
              <input
                type="radio"
                name="marketplaceType"
                value={MarketplaceType.Lamoda}
                onChange={handleMarketplaceChange}
                checked={marketplaceType === MarketplaceType.Lamoda}
                disabled={!isTokenValid}
              />
              <span className={styles.lamoda}>Lamoda</span>
            </label>
            <label>
              <input
                type="radio"
                name="marketplaceType"
                value={MarketplaceType.SberMegaMarket}
                onChange={handleMarketplaceChange}
                checked={marketplaceType === MarketplaceType.SberMegaMarket}
                disabled={!isTokenValid}
              />
              <span className={styles.sberMegaMarket}>СберМегаМаркет</span>
            </label>
            <label>
              <input
                type="radio"
                name="marketplaceType"
                value={MarketplaceType.YandexMarket}
                onChange={handleMarketplaceChange}
                checked={marketplaceType === MarketplaceType.YandexMarket}
                disabled={!isTokenValid}
              />
              <span className={styles.yandexMarket}>Яндекс Маркет</span>
            </label>
          </div>
        </div>

        {marketplaceType === MarketplaceType.Wildberries && (
          <>
            <div>
              <div className={styles.actionCardHeader}>
                <CustomIcon name="document" className={styles.icon} />
                <div className={styles.stepLabel}>Загрузите АКТ приемки</div>
              </div>

              <AcceptanceCertificate
                key={sentCount}
                onChange={(
                  file,
                  isLegal,
                  isAddressEmpty,
                  needConfirm,
                  hasCriticalErrors
                ) => {
                  setUploadedFile(file);
                  setIsLegalEntity(isLegal);
                  setIsAddressMissing(isAddressEmpty);
                  setNeedConfirm(needConfirm);
                  setHasCriticalErrors(hasCriticalErrors);
                }}
                token={token!}
                trueApiToken={trueApiToken}
                disabled={!isTokenValid || !certificate}
              />
            </div>
          </>
        )}

        {marketplaceType === MarketplaceType.Lamoda && (
          <>
            <div>
              <div className={styles.actionCardHeader}>
                <CustomIcon name="document" className={styles.icon} />
                <div className={styles.stepLabel}>Загрузите файл поставки</div>
              </div>

              <LamodaFile
                key={sentCount}
                onChange={(
                  file,
                  isLegal,
                  isAddressEmpty,
                  inn,
                  needConfirm,
                  hasCriticalErrors
                ) => {
                  setUploadedFile(file);
                  setIsLegalEntity(isLegal);
                  setIsAddressMissing(isAddressEmpty);
                  setSellerInn(inn);
                  setNeedConfirm(needConfirm);
                  setHasCriticalErrors(hasCriticalErrors);
                }}
                token={token!}
                trueApiToken={trueApiToken}
                disabled={!isTokenValid || !certificate}
              />
            </div>
          </>
        )}

        {(marketplaceType === MarketplaceType.Ozon ||
          marketplaceType === MarketplaceType.DetMir ||
          marketplaceType === MarketplaceType.SberMegaMarket ||
          marketplaceType === MarketplaceType.YandexMarket) && (
          <div>
            <div className={styles.actionCardHeader}>
              <CustomIcon name="document" className={styles.icon} />
              <div className={styles.stepLabel}>Загрузите УПД</div>
            </div>

            <UpdUploader
              key={`${sentCount}-${marketplaceType}`}
              onChange={(file, isLegal, needConfirm, hasCriticalErrors) => {
                setUploadedFile(file);
                setIsLegalEntity(isLegal);
                setNeedConfirm(needConfirm);
                setHasCriticalErrors(hasCriticalErrors);
              }}
              token={token!}
              trueApiToken={trueApiToken}
              disabled={!isTokenValid || !certificate}
              marketplace={marketplaceType}
            />
          </div>
        )}

        {shouldAskAdditionalDetails(
          isLegalEntity,
          isAddressMissing,
          marketplaceType
        ) && (
          <div>
            <div className={styles.actionCardHeader}>
              <CustomIcon name="person" className={styles.icon} />
              <div className={styles.stepLabel}>
                Введите дополнительные данные
              </div>
            </div>

            <AdditionalDetails
              key={`${sentCount}-${uploadedFile?.name}`}
              onChange={(info) => {
                setAdditionalInfo(info ?? undefined);
              }}
              disabled={!isTokenValid}
              isAddressMissing={isAddressMissing}
              isLegalEntity={isLegalEntity}
              marketplaceType={marketplaceType}
            />
          </div>
        )}

        <div>
          <div className={styles.actionCardHeader}>
            <CustomIcon name="updAction" className={styles.icon} />
            <div className={styles.stepLabel}>Что сделать с УПД</div>
          </div>

          <div className={styles.updActionTypes}>
            <label hidden={marketplaceType === MarketplaceType.YandexMarket}>
              <input
                type="radio"
                name="updActionType"
                value={ActionType.SignAndSend}
                onChange={handleUpdActionTypeChange}
                checked={updActionType === ActionType.SignAndSend}
                disabled={!isTokenValid}
              />
              <span>Подписать и отправить</span>
            </label>
            <label>
              <input
                type="radio"
                name="updActionType"
                value={ActionType.Download}
                onChange={handleUpdActionTypeChange}
                checked={updActionType === ActionType.Download}
                disabled={!isTokenValid}
              />
              <span>Только скачать</span>
              {marketplaceType === MarketplaceType.YandexMarket && (
                <Tooltip
                  id="yandex-market-tooltip"
                  message="Яндекс пока не поддерживает роуминг через Эдо Лайт. Можно только скачать УПД с исправлением всех ошибок, чтобы отправить его через Диадок или Сбис."
                />
              )}
            </label>
          </div>
        </div>

        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleOnClick}
          className={styles.sendButton}
          disabled={clickButtonDisabled()}
        >
          <div className={styles.sendButtonText}>
            {updActionType === ActionType.SignAndSend ? "Отправить" : "Скачать"}
          </div>
        </Button>

        <Loader
          show={isParsing}
          message={
            updActionType === ActionType.SignAndSend
              ? "Отправляем УПД в Честный Знак"
              : "Скачиваем УПД"
          }
        />
      </div>

      {!hasCriticalErrors && (
        <ConfirmDialog
          title="Внимание, в документе есть неточности"
          content={
            <>
              ⚠️ Файл содержит КИЗ(ы) с <b>некритичными</b> ошибками, если
              отправить такой документ, то УПД не будет принят маркетплейсом. Но
              мы можем все исправить.
              <br />
              <br />
              Вы можете нажать:
              <ol>
                <li>
                  <b>ОТМЕНА</b> - мы уже отправили файл со списком ошибочных КИЗ
                  вам в телеграм. Вы можете самостоятельно посмотреть ошибки и
                  разобраться в них
                </li>
                <li>
                  <b>ОТПРАВИТЬ</b> - тогда наш бот сам удалит ошибочные КИЗ,
                  сформирует и отправит корректный УПД, который 100% примет
                  маркетплейс.
                </li>
              </ol>
            </>
          }
          noButton="Отмена"
          yesButton="Отправить"
        />
      )}

      {hasCriticalErrors && (
        <ConfirmDialog
          title="⚠️ В документе есть критичная ошибка ⚠️"
          content={
            <ul>
              <li>
                Сформировать УПД невозможно, вам необходимо исправить ошибку!
              </li>
              <br />
              <li>
                В файле содержатся КИЗ(ы), которые не введены в оборот. Для
                отправки УПД, сначала введите КИЗ(ы) в оборот и после этого
                повторите операцию.
              </li>
              <br />
              <li>
                Файл с ошибочными КИЗ и инструкцией как ввести их в оборот уже
                отправлен вам в телеграм.
              </li>
            </ul>
          }
          yesButton="Перейти в бот"
          yesButtonLink={process.env.REACT_APP_TG_BOT_URL}
        />
      )}
    </div>
  );
}
function shouldAskAdditionalDetails(
  isLegalEntity: boolean,
  isAddressMissing: boolean,
  marketplaceType: MarketplaceType
) {
  return (
    isLegalEntity ||
    isAddressMissing ||
    marketplaceType === MarketplaceType.Lamoda
  );
}
